<template>
    <div class='login-body'
         style='background-color: #0B2142'>
        <Loading
            :active='loading'
            color='#eeeee4'
            background-color='#0B2142'
            loader='Spinner' />
        <div class='login-panel'></div>
        <div class='login-content'>
            <img src='/layout/images/rp100h.png' alt='Real Plaza' />
            <h1><span>Inicia sesión en</span> golen - Real Plaza</h1>
            <div v-show='showForm'>
                <p>Bienvenido, acceda con su credencial corporativa.</p>
                <div class='login-input-wrapper'>
                    <Button
                        class='w-full'
                        label='Acceder ...'
                        :loading='loadingButton'
                        @click='login'
                    />
                </div>
                <div class='login-input-wrapper'>
                    <Button
                        class='w-full'
                        label='Cambiar credencial de acceso'
                        :loading='loadingButton'
                        @click='changeAccess'
                    />
                </div>
            </div>
        </div>
    </div>
    <Toast group='login' />
    <Dialog
        header='TÉRMINOS Y CONDICIONES'
        v-model:visible='displayModalTC'
        :breakpoints="{'960px': '75vw', '640px': '90vw'}"
        :style="{width: '50vw'}"
        :modal='true'>
        <vue-pdf-app
            id='tc'
            style='height: 100vh;'
            :pdf='urlTC' />
        <template #footer>
            <Button
                label='No Aceptar'
                icon='pi pi-times'
                @click='declineAcceptTerms'
                class='p-button-text' />
            <Button
                label='Aceptar Terminos'
                icon='pi pi-check'
                @click='saveAcceptTerms'
                autofocus />
        </template>
    </Dialog>
    <Dialog
        header='TRATAMIENTO OPCIONAL DE DATOS PERSONALES'
        v-model:visible='displayModalTODP'
        :breakpoints="{'960px': '75vw', '640px': '90vw'}"
        :style="{width: '50vw'}"
        :modal='true'>
        <vue-pdf-app
            id='todp'
            style='height: 100vh;'
            :pdf='urlTODP' />
        <template #footer>
            <Button
                label='No Aceptar'
                icon='pi pi-times'
                @click='declineAcceptPersonalDataProtection'
                class='p-button-text' />
            <Button
                label='Aceptar Tratamiento de Datos'
                icon='pi pi-check'
                @click='saveAcceptPersonalDataProtection'
                autofocus />
        </template>
    </Dialog>
</template>

<script setup lang='ts'>
import { inject, ref } from 'vue';
import { useToast } from 'primevue/usetoast';
import router from '@/router';
import Store from '@/data/Store';
// import global from '@/data/global';
// import ERouteType from '@/router/ERouteType';
import UserAPI from '@/data/api/UserAPI';
import User from '@/data/entity/User';
import { useRoute } from 'vue-router';

const {
    auth,
    loggedIn,
} = inject<Store>('store', {});

if (auth?.authenticate) {
    console.log('Primero cierre session');
    router.push('/');
    // if (global.isSuperUser()) {
    //     router.push(ERouteType.LICENSE_LIST_PATH);
    // } else if (global.isLawyer()) {
    //     router.push(ERouteType.LEGAL_RULE_LIST_PATH);
    // } else if (global.isAdmin()) {
    //     router.push(ERouteType.USER_LIST_PATH);
    // } else if (global.isUser()) {
    //     router.push(ERouteType.LEGAL_RULE_LIST_BY_CRITERIA_PATH);
    // }
}

const federatedCode = useRoute().query?.code as string;

const toast = useToast();

const loadingButton = ref(false);

const displayModalTC = ref(false);
const displayModalTODP = ref(false);

const loading = ref(false);
const showForm = ref(true);

const urlTC = 'https://files-golex.s3.us-east-2.amazonaws.com/terms/TC_Usuarios_de_Empresas.pdf';
const urlTODP = 'https://files-golex.s3.us-east-2.amazonaws.com/terms/TC_para_tratamiento_de_datos_opcionales_de_USUARIOS_de_Empresas.pdf';


const dataInit = ref<any>();


const login = async () => {
    loadingButton.value = true;
    window.location.href = 'https://golen.auth.us-east-2.amazoncognito.com/oauth2/authorize?client_id=1k6kkpqp8dqvml94lb99hveeio&response_type=code&scope=email+openid&redirect_uri=https%3A%2F%2Fgolen.golencompliance.com%2Frealplaza';
};

const changeAccess = async () => {
    loadingButton.value = true;
    window.location.href = 'https://golen.auth.us-east-2.amazoncognito.com/login?redirect_uri=https%3A%2F%2Fgolen.golencompliance.com%2Frealplaza&response_type=code&client_id=1k6kkpqp8dqvml94lb99hveeio';
};

const saveAcceptTerms = async () => {
    displayModalTC.value = false;

    const data = dataInit.value;

    const user = new User();
    user.id = data.id;

    await UserAPI.updateAcceptTermsByUser(user);
    displayModalTODP.value = true;

};

const declineAcceptTerms = async () => {

    displayModalTC.value = false;
    toast.add({
        severity: 'warn',
        detail: 'Debe aceptar los términos y condiciones para continuar',
        life: 3000,
        group: 'login',
    });
    if (!federatedCode) {
        router.push('/login');
    } else {
        redirectToFederatedUrl();
    }
};

const saveAcceptPersonalDataProtection = async () => {
    displayModalTODP.value = false;

    const data = dataInit.value;
    loggedIn!(data.name, data, data.email, data.token);

    const user = new User();
    user.id = data.id;
    user.acceptPersonalDataProtection = 1;

    await UserAPI.updateAcceptPersonalDataProtection(user);

    toast.add({
        severity: 'success',
        detail: 'Acepto la proteccion de datos personales y recibira notificciones',
        life: 3000,
        group: 'login',
    });
    toast.add({ severity: 'success', detail: 'Inicio de sesión autorizado', life: 3000, group: 'login' });
    setTimeout(() => {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
    }, 500);


};

const declineAcceptPersonalDataProtection = async () => {
    displayModalTODP.value = false;
    const data = dataInit.value;
    loggedIn!(data.name, data, data.email, data.token);
    toast.add({
        severity: 'warn',
        detail: 'No acepto la proteccion de datos personales y no recibira notificciones',
        life: 3000,
        group: 'login',
    });
    toast.add({ severity: 'success', detail: 'Inicio de sesión autorizado', life: 3000, group: 'login' });
    setTimeout(() => {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
    }, 500);
};

const redirectToFederatedUrl = async () => {
    window.location.href = 'https://golen.auth.us-east-2.amazoncognito.com/oauth2/authorize?client_id=1k6kkpqp8dqvml94lb99hveeio&response_type=code&scope=email+openid&redirect_uri=https%3A%2F%2Fgolen.golencompliance.com%2Frealplaza';
};

</script>

<style scoped>

</style>