export default class ERolesType {
  static readonly SUPER_USER: number = 1;

  static readonly LAWYER: number = 2;

  static readonly ADMIN: number = 3;

  static readonly USER: number = 4;

  static readonly LAWYER_USER: number = 5;
}
