import { reactive, readonly } from 'vue';
import Auth from '@/data/Auth';
import EUserType from '@/data/entity/enums/EUserType';
import UserType from '@/data/entity/UserType';
import router from '@/router';

const authStorage = localStorage.getItem('auth');

const auth = reactive(authStorage ? JSON.parse(authStorage) : new Auth(false));

const loggedIn = (username: any, user: any, tokenType: any, token: any) => {
    const data = token.split('.')[1];

    const newUser = {
        licenseId: user.licenseId,
        id: user.id,
        name: user.name,
        lastName: user.lastName,
        accessTypeId: user.accessTypeId,
        accessTypeName: user.accessTypeName,
    };

    const newLicense = {
        id: user.licenseId,
        matrixByProject: user.matrixByProject,
        showProjectEvaluations: user.showProjectEvaluations,
        activeDeleteLegalMatrix: user.activeDeleteLegalMatrix,
        exportMatrixToExcel: user.exportMatrixToExcel,
        assignUsersToMatrix: user.assignUsersToMatrix,
        activeThemesAndSubThemes: user.activeThemesAndSubThemes,
        activeKeywords: user.activeKeywords,
        activeImpact: user.activeImpact,
        activeConsequencesNoCompliance: user.activeConsequencesNoCompliance,
        activeImpactCriteria: user.activeImpactCriteria,
        activeArticleIsPeriodic: user.activeArticleIsPeriodic,
        activePeriodicityOfArticle: user.activePeriodicityOfArticle,
        activeExecutor: user.activeExecutor,
        activeLegalBase: user.activeLegalBase,
        activeEvidenceCompliance: user.activeEvidenceCompliance,
        activeRecommendationsOfRequirements: user.activeRecommendationsOfRequirements,
        generateActionPlan: user.generateActionPlan,
        assignAmbit: user.assignAmbit,
        generateSummarizeAllArticles: user.generateSummarizeAllArticles,
        generateRecommendationAllArticles: user.generateRecommendationAllArticles,
        generateInfractionAllArticles: user.generateInfractionAllArticles,
        evidenceOfComplianceRequired: user.evidenceOfComplianceRequired,
        activeReplicateEvaluation: user.activeReplicateEvaluation,
        activeObservationEvaluation: user.activeObservationEvaluation,
        activeDeleteEvaluation: user.activeDeleteEvaluation,
        generateEvidenceForAllArticles: user.generateEvidenceForAllArticles,
        independentLegalRuleSummary: user.independentLegalRuleSummary,
        generateSummariesOfLegalRule: user.generateSummariesOfLegalRule,
    };

    auth.authenticate = true;
    auth.user = newUser;
    auth.user.name = user.name;
    auth.user.licenseId = user.licenseId;
    auth.license = newLicense;
    // auth.user.userId = user.id;
    // auth.user = JSON.parse(atob(data));
    // auth.user.username = username;
    auth.token = token;
    // auth.tokenType = tokenType;
    // auth.roles = JSON.parse(atob(data)).roles;
    // auth.userType = JSON.parse(atob(data)).userType;
    auth.accessTypeId = user.accessTypeId;
    auth.licenseId = user.licenseId;
    auth.userId = user.id;

    // console.log('auth', auth);

    localStorage.setItem('auth', JSON.stringify(auth));
};

const logOut = () => {
    auth.authenticate = false;
    auth.user = null;
    auth.token = null;

    localStorage.clear();

    // Uso de router.replace para evitar el retorno al historial anterior
    router.replace('/login').catch(err => {
        // Manejar errores
        console.error(err);
    });
};
let userOptions: any[] = [];
let userType: UserType;
let accessTypeId: number;

if (auth?.authenticate) {
    userOptions = auth.roles!;
    userType = auth.userType;
    accessTypeId = auth.accessTypeId;
}
const isSuperUser = () => accessTypeId === EUserType.SUPERUSER;
const isLawyer = () => accessTypeId === EUserType.LAWYER;
const isAdmin = () => accessTypeId === EUserType.ADMIN;
const isUser = () => accessTypeId === EUserType.USER;
const isLawyerUser = () => accessTypeId === EUserType.LAWYER_USER;

// const isRegisterOrders = () => userOptions.includes('REGISTRO_DE_ORDENES');
// const isConfigAccount = () => userOptions.includes('CONFIGURACION_DE_LA_CUENTA');
// const isLaboratoryWorker = () => userType.id === EUserType.LABORATORISTA;

export default {
    auth: readonly(auth),
    loggedIn,
    logOut,
    isSuperUser,
    isLawyer,
    isAdmin,
    isUser,
    isLawyerUser,
};
