import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { reactive } from 'vue';
import Auth from '@/data/Auth';

const authStorage = localStorage.getItem('auth');

const auth = reactive(authStorage ? JSON.parse(authStorage) : new Auth(false));

const { token } = auth;

class AxiosConfig {
    private static instance: AxiosConfig;

    public axios: AxiosInstance;

    private errorMessage = {
        serverDown: 'Probablemente tenemos inconvenientes con nuestro servidor o revise su conexion a internet.',
        authorization: 'No se pudo acceder al sistema',
    };

    private constructor() {
        // this.errorMessage = new Error();
        this.axios = axios.create({
            baseURL: process.env.VUE_APP_API_URL,
        });

        // request header
        this.axios.interceptors.request.use(
            async (config) => {
                config.headers.setContentType('application/json');
                // TODO: Agregar autenticación en está parte
                config.headers.setAuthorization(`Bearer ${token}`);
                // const configAux = config;
                //
                // configAux.headers = {
                //     'Content-Type': 'application/json',
                //     Authorization: `Bearer ${token}`,
                // };
                // return configAux;
                return config;
            },

            (error: any) => ({
                status: -1,
                message: 'Error',
            }),
        );

        // response parse
        this.axios.interceptors.response.use(
            (response: AxiosResponse) => {
                // console.log('response', response);
                return response;
            },
            async (error: any) => {
                let errorObj = {};
                if (error.response && error.response.status && [401, 404].indexOf(error.response.status) > -1) {
                    // console.log('error 1', error);
                    // window.location.href = `/error?code=${error.response.status}`;
                    errorObj = {
                        status: error.response.status,
                        message: error.response.data.error,
                        observations: error.response.data.observations,
                    };
                } else if (error.response && error.response.status && error.response.status >= 400
                    && error.response.status < 500 && error.response.data && error.response.data.observations) {
                    // console.log('error 2', error.response);
                    errorObj = {
                        status: error.response.status,
                        message: error.response.data.error,
                        observations: error.response.data.observations,
                    };
                } else if (error.request && error.request.status === 0) {
                    console.log('error 3', error);
                    // window.location.href = `/error?code=0`;
                    errorObj = {
                        status: 0,
                        message: 'Error al conectar con el servidor',
                        observations: ['Problema de conexión a internet', 'El token de acceso ha expirado'],
                    };
                } else {
                    // console.log('error 4', error.response);
                    errorObj = {
                        status: error.response.status,
                        message: error.response.data.error,
                        observations: [this.errorMessage.serverDown],
                    };
                }
                return Promise.reject(errorObj);
            },
        );
    }

    public static getInstance(): AxiosConfig {
        if (!AxiosConfig.instance) {
            AxiosConfig.instance = new AxiosConfig();
        }

        return AxiosConfig.instance;
    }
}

export default AxiosConfig.getInstance().axios;
