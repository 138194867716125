<template>
    <Toast group='recover-password' />
    <div class='login-body'
         style='background-color: #0B2142'>
        <div class='login-panel'></div>
        <div class='login-content'>
            <img src='layout/images/logo-h100.png' alt='Golen' />
            <h1><span>Recupera tu contraseña</span> golen</h1>
            <p>Bienvenido, utilice el formulario para recuperar tu contraseña.</p>

            <div class='login-input-wrapper'>
                <InputText
                    v-model='v$.email.$model'
                    @keyup.enter.prevent='passwordRecovery'
                    placeholder='Ingrese el correo electronico'
                />
                <i class='pi pi-user'></i>
            </div>
            <small
                v-if='(v$.email.$errors.length>0)'
                class='p-error'>
                <p
                    v-for='error of v$.email.$errors'
                    :key='error.$uid'>{{ error.$message }}</p>
            </small>
            <div class='login-input-wrapper text-right'>
                <a
                    @click='returnLogin'
                    class='font-medium text-white hover:text-gray-400 cursor-pointer'>
                    &lt;- Regresar al login
                </a>
            </div>

            <div class='login-input-wrapper'>
                <Button
                    class='w-full'
                    label='Recuperar'
                    :loading='loadingButton'
                    :disabled='btnAcceptDisable'
                    @click='passwordRecovery'
                />
            </div>

        </div>
    </div>
</template>

<script setup lang='ts'>
import {
    computed, inject, reactive, ref,
} from 'vue';
import { email, helpers, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { useToast } from 'primevue/usetoast';
import Store from '@/data/Store';
import router from '@/router';
import UserDto from '@/data/entity/dto/UserDto';
import UserAPI from '@/data/api/UserAPI';

const {
    auth,
} = inject<Store>('store', {});

if (auth?.authenticate) {
    router.push('/');
}

const toast = useToast();

const loadingButton = ref(false);

const formState = reactive({
    email: '',
});

const rules = computed(() => ({
    email: {
        email: helpers.withMessage('Email no es válido', email),
        required: helpers.withMessage('Email es obligatorio', required),
    },
}));

const v$ = useVuelidate(rules, formState, { $autoDirty: true });

const btnAcceptDisable = computed(() => {
    if (v$.value.$invalid) {
        return true;
    }
    return false;
});

const returnLogin = async () => {
    setTimeout(() => {
        router.push('/login');
    }, 300);
};

const passwordRecovery = async () => {
    await v$.value.$validate();

    if (v$.value.email.$error) {
        return;
    }

    loadingButton.value = true;

    const user = new UserDto();
    user.email = formState.email;

    UserAPI.PasswordRecovery(user)
        .then((data) => {
            toast.add({
                severity: 'success',
                summary: data.message,
                life: 3000,
                group: 'recover-password',
            });

            setTimeout(() => {
                router.push('/login');
            }, 1000);
        })
        .catch((error) => {
            console.log(error.message);
            toast.add({
                severity: 'error',
                summary: 'Los datos ingresados no son correctos',
                life: 3000,
                group: 'recover-password',
            });
        })
        .finally(() => {
            loadingButton.value = false;
        });
};

</script>
