import api from '@/helpers/AxiosConfig';
import BaseApi from '@/data/api/BaseApi';
import User from '@/data/entity/User';
import Subscription from '@/data/entity/Subscription';
import Keyword from '@/data/entity/Keyword';
import Unsubscribe from '@/data/entity/Unsubscribe';

const URL = 'https://5yjh0qxq04.execute-api.us-east-2.amazonaws.com/dev/api/subscription';

class SubscriptionAPI extends BaseApi {
  constructor() {
    super();
    this.url = URL;
  }

  GetUsersByLicenseId = async (pLicenseId: number): Promise<User[]> => {
    const response = await api.get<User[]>(`${this.url}/license`, { params: { licenseId: pLicenseId } });
    return (response && response.data) || null;
  };

  GetUsersById = async (pUserId: number): Promise<User[]> => {
    const response = await api.get<User[]>(`${this.url}/user`, { params: { userId: pUserId } });
    return (response && response.data) || null;
  };

  InsertSubscription = async (subscription?: Subscription): Promise<any> => {
    const response = await api.post<any>(`${this.url}/new-legal-rule`, subscription);
    return (response && response.data) || null;
  };

  SendEmailForVerification = async (subscription?: Subscription): Promise<any> => {
    const response = await api.post<any>(`${this.url}/send-email-for-verification`, subscription);
    return (response && response.data) || null;
  };

  UpdateVerificationEmail = async (subscription?: Subscription): Promise<any> => {
    const response = await api.put<any>(`${this.url}/verification-email`, subscription);
    return (response && response.data) || null;
  };

  InsertUnsubscription = async (unsubscribe?: Unsubscribe): Promise<any> => {
    const response = await api.post<any>(`${this.url}/unsubscribe`, unsubscribe);
    return (response && response.data) || null;
  };

  GetSubscriptionByToken = async (pToken: string): Promise<any[]> => {
    const response = await api.get<any[]>(`${this.url}/by-token`, { params: { code: pToken } });
    return (response && response.data) || null;
  };
}

export default new SubscriptionAPI();
