import api from '@/helpers/AxiosConfig';
import License from '@/data/entity/License';
import BaseApi from '@/data/api/BaseApi';
import LicenseExportMatrixColum from '@/data/entity/LicenseExportMatrixColum';
import Province from '@/data/entity/Province';

const URL = 'https://49540gg2ie.execute-api.us-east-2.amazonaws.com/dev/api/licenses';

class LicenseAPI extends BaseApi {
    constructor() {
        super();
        this.url = URL;
    }

    GetLicenses = async (): Promise<License[]> => {
        const response = await api.get<License[]>(this.url);
        return (response && response.data) || null;
    };

    GetClients = async (): Promise<License[]> => {
        const response = await api.get<License[]>(`${this.url}/clients`);
        return (response && response.data) || null;
    };

    GetLicenseById = async (pLicenseId: number): Promise<License> => {
        const response = await api.get<License>(`${this.url}/license`, { params: { licenseId: pLicenseId } });
        return (response && response.data) || null;
    };

    GetLicenseByUserId = async (pUserId: number, pLicenseId: number): Promise<License[]> => {
        const response = await api.get<License[]>(`${this.url}/user`, { params: {
            userId: pUserId,
            licenseId: pLicenseId,
            } });
        return (response && response.data) || null;
    };

    GetLicenseUseAiStatus = async (pLicenseId: number): Promise<License[]> => {
        const response = await api.get<License[]>(`${this.url}/license-use-ai-status`, {
            params: {
                licenseId: pLicenseId,
            },
        });
        return (response && response.data) || null;
    };

    InsertLicense = async (license?: License): Promise<any> => {
        const response = await api.post<any>(this.url, license);
        return (response && response.data) || null;
    };

    UpdateLicense = async (license?: License): Promise<any> => {
        const response = await api.put<any>(this.url, license);
        return (response && response.data) || null;
    };

    UpdateCompanyInformation = async (license?: License): Promise<any> => {
        const response = await api.put<any>(`${this.url}/license/company-information`, license);
        return (response && response.data) || null;
    };

    UpdateUseCompanyInformationForAi = async (license?: License): Promise<any> => {
        const response = await api.put<any>(`${this.url}/license/use-company-information-for-ai`, license);
        return (response && response.data) || null;
    };

    GetExportMatrixColumnByLicense = async (pLicenseId: number): Promise<License> => {
        const response = await api.get<License>(`${this.url}/export-matrix-column-by-license`, { params: { licenseId: pLicenseId } });
        return (response && response.data) || null;
    };

    UpdateLicenseExportMatrixColumn = async (licenseExportMatrixColum?: LicenseExportMatrixColum): Promise<any> => {
        const response = await api.put<any>(`${this.url}/license-export-matrix-column`, licenseExportMatrixColum);
        return (response && response.data) || null;
    };

    GetAllLicenseForAssociate = async (): Promise<License[]> => {
        const response = await api.get<License[]>(`${this.url}/all-licenses-for-associate`);
        return (response && response.data) || null;
    };

    GetLicenseAssociatesByLicense = async (pLicenseId: number): Promise<any[]> => {
        const response = await api.get<any[]>(`${this.url}/license-associates-by-license`, {
            params: {
                licenseId: pLicenseId,
            },
        });
        return (response && response.data) || null;
    };
}

export default new LicenseAPI();
