export default class NotificationUser {
    id?: number;

    notificationId?: number;

    licenseId?: number;

    user_id?: number;

    viewed?: number;

    closed?: number;

    createDate?: string;

    updateDate?: string;
}
