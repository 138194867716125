export default class Auth {
    authenticate = false;

    user?: {
        licenseId: number;
        id: number;
        name: string,
        lastName: string,
        accessTypeId: number,
        accessTypeName: string,
    };

    license?: {
        id: number;
        matrixByProject: number,
        showProjectEvaluations: number,
        activeDeleteLegalMatrix: number,
        exportMatrixToExcel: number,
        assignUsersToMatrix: number,
        activeThemesAndSubThemes: number,
        activeKeywords: number;
        activeImpact: number;
        activeConsequencesNoCompliance: number;
        activeImpactCriteria: number;
        activeArticleIsPeriodic: number;
        activePeriodicityOfArticle: number;
        activeExecutor: number;
        activeLegalBase: number;
        activeEvidenceCompliance: number;
        activeRecommendationsOfRequirements: number;
        generateActionPlan: number;
        assignAmbit: number;
        generateSummarizeAllArticles: number;
        generateRecommendationAllArticles: number;
        generateInfractionAllArticles: number;
        evidenceOfComplianceRequired: number;
        activeReplicateEvaluation: number;
        activeObservationEvaluation: number;
        activeDeleteEvaluation: number;
        generateEvidenceForAllArticles: number;
        independentLegalRuleSummary: number;
        generateSummariesOfLegalRule: number;
    };

    userId?: number;

    token?: string;

    roles?: string[];

    userType?: { id: number, name: string };

    accessTypeId?: number;

    licenseId?: number;

    constructor(authenticate: boolean,
                user?: {
                    licenseId: number;
                    id: number;
                    name: string;
                    lastName: string;
                    accessTypeId: number;
                    accessTypeName: string;
                },
                license?: {
                    id: number;
                    matrixByProject: number;
                    showProjectEvaluations: number;
                    activeDeleteLegalMatrix: number;
                    exportMatrixToExcel: number;
                    assignUsersToMatrix: number;
                    activeThemesAndSubThemes: number;
                    activeKeywords: number;
                    activeImpact: number;
                    activeConsequencesNoCompliance: number;
                    activeImpactCriteria: number;
                    activeArticleIsPeriodic: number;
                    activePeriodicityOfArticle: number;
                    activeExecutor: number;
                    activeLegalBase: number;
                    activeEvidenceCompliance: number;
                    activeRecommendationsOfRequirements: number;
                    generateActionPlan: number;
                    assignAmbit: number;
                    generateSummarizeAllArticles: number;
                    generateRecommendationAllArticles: number;
                    generateInfractionAllArticles: number;
                    evidenceOfComplianceRequired: number;
                    activeReplicateEvaluation: number;
                    activeObservationEvaluation: number;
                    activeDeleteEvaluation: number;
                    generateEvidenceForAllArticles: number;
                    independentLegalRuleSummary: number;
                    generateSummariesOfLegalRule: number;
                },
                userId?: number,
                token?: string,
                roles?: string[],
                userType?: { id: number, name: string },
                accessTypeId?: number,
                licenseId?: number) {
        this.authenticate = authenticate;
        this.user = user;
        this.license = license;
        this.userId = userId;
        this.token = token;
        this.roles = roles;
        this.userType = userType;
        this.accessTypeId = accessTypeId;
        this.licenseId = licenseId;
    }
}
