export default class User {
  id?: number;

  cognitoId?: string;

  name?: string;

  lastName?: string;

  documentNumber?: string;

  password?: string;

  email?: string;

  cellPhoneNumber?: string;

  accessTypeId?: number;

  stateUserId?: number;

  licenseId?: number;

  acceptPersonalDataProtection?: number;

  notificationNewLegalRule?: number;

}
