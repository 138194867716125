<template>
      <div class='wizard-body-subscription'>
        <div class='wizard-wrapper'>
            <div class='wizard-header'>
                <div class='wizard-logo'>
                    <router-link to='/subscription'>
                        <img src='layout/images/logo-h100.png' alt='babylon-layout' />
                    </router-link>
                </div>
            </div>

            <div class='wizard-content'>
                <div class="wizard-card">
                    <div class="wizard-card-content">
                        <div class="wizard-forms-wrapper grid grid-nogutter align-content-center align-items-center justify-content-center">
                            <div class='col-10 md:col-5 align-items-stretch'>
                                <div class='w-100 p-5'>
                                    <h1 class='mb-4 text-white'>Proceso para eliminar suscripción.</h1>
                                    <div class='w-100 d-flex align-items-start'>
                                        <div class='icon d-flex align-items-center justify-content-center'>
                                            <span class='fa fa-paper-plane'></span>
                                        </div>
                                        <div class='text pl-4'>
                                            <p><span class='text-white-alpha-80'>Correo:</span> <a
                                                href='mailto:informes@golencompliance.com'>informes@golencompliance.com</a>
                                            </p>
                                        </div>
                                    </div>
                                    <div class='w-100 d-flex align-items-start'>
                                        <div class='icon d-flex align-items-center justify-content-center'>
                                            <span class='fa fa-globe'></span>
                                        </div>
                                        <div class='text pl-4'>
                                            <p><span class='text-white-alpha-80'>Sitio Web</span> <a
                                                href='https://golencompliance.com/'
                                                target='_blank'>golencompliance.com</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class='col-10 md:col-5'>
                                <div
                                    style='background-color: #0B2142;
                                                        border-radius: 30px'>
                                    <div
                                        style='padding: 20px 40px'>
                                        <div
                                            v-if='!isUnsubscribed'>
                                            <div class=''>
                                                <div class='md:col-12 py-2'>
                                                    <div>
                                                        <label
                                                            class='text-white'
                                                            for='motive'
                                                            :class="{'p-error':v$.motive.$errors.length>0}">Motivo<span
                                                            class='text-red-600'> *</span>
                                                        </label>
                                                        <Textarea
                                                            id='motive'
                                                            rows='5'
                                                            autocomplete='off'
                                                            v-model.trim='v$.motive.$model'
                                                            class='w-full'
                                                            placeholder='Por favor ingrese el motivo'
                                                            :class="{'p-invalid':v$.motive.$errors.length>0}"/>
                                                        <small
                                                            v-if='(v$.motive.$errors.length>0)'
                                                            class='p-error'>
                                                            <p
                                                                v-for='error of v$.motive.$errors'
                                                                :key='error.$uid'>{{ error.$message }}</p>
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class='md:col-12 py-2'>
                                                <Button
                                                    style='background-color: #BF5305; border-color: #BF5305;'
                                                    @click='handleSubmit'
                                                    :disabled='v$.$invalid'
                                                >{{ btnName }}
                                                </Button>
                                            </div>
                                        </div>
                                        <div
                                            v-if='isUnsubscribed'>
                                            <p
                                                style='
                color: #FFFFFF;
                font-family: Helvetica,Sans-serif;
                font-weight:bold;
                font-size: 20px'>
                                                ¡¡¡Se ha eliminado suscripción de forma exitosa!!!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang='ts'>

import {
    computed, inject, reactive, ref,
} from 'vue';
import useVuelidate from '@vuelidate/core';
import {
    helpers, required,
} from '@vuelidate/validators';
import router from '@/router';
import { useToast } from 'primevue/usetoast';
import SubscriptionAPI from '@/data/api/SubscriptionAPI';
import Unsubscribe from '@/data/entity/Unsubscribe';
import Store from '@/data/Store';
import { useRoute } from 'vue-router';

const {
    auth,
} = inject<Store>('store', {});

if (auth?.authenticate) {
    console.log('Primero cierre session');
    router.push('/');
}

const recoveryId = useRoute().query?.code as string;

if (!recoveryId) {
    router.push('/login');
}

const toast = useToast();

let unsubscribe: Unsubscribe;
let subscriber: any;

const btnName = ref('Eliminar suscripción');


const isUnsubscribed = ref(false);


const formState = reactive({
    motive: '',
});

const rules = computed(() => ({
        motive: {
            required: helpers.withMessage('El motivo es obligatorio', required),
        },
    }
));

const v$ = useVuelidate(rules, formState, { $autoDirty: true });

const handleSubmit = async () => {
    await v$.value.$validate();

    if (v$.value.$invalid) {
        toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Por favor, revise los campos del formulario',
            life: 3000,
        });
    } else {
        unsubscribe = {
            subscriberId: subscriber !== null ? subscriber.id : 0,
            motive: formState.motive,
        };
        await SubscriptionAPI.InsertUnsubscription(unsubscribe)
            .then(async (response) => {
                toast.add({ severity: 'success', summary: 'Éxito', detail: response.message, life: 3000 });
                isUnsubscribed.value = true;
            })
            .catch((error) => {
                toast.add({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
            });
    }
};

const mounted = async () => {
    const promises: Promise<any>[] = [];

    promises.push(SubscriptionAPI.GetSubscriptionByToken(recoveryId));

    Promise.all(promises)
        .then((values) => {
            const [pSubscriber] = values;
            subscriber = pSubscriber;
        })
        .catch(() => {
            toast.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar los datos', life: 3000 });
        })
        .finally(() => {
            toast.removeAllGroups();
        });
};
mounted();

// eslint-disable-next-line no-unused-vars
const goToback = () => {
    router.go(-1);
};
</script>

<style lang='scss' scoped>

.wizard-body-subscription {
    background-image:
        linear-gradient(45deg,
            rgb(11, 33, 66,0.7),
            rgb(11, 33, 66,0.7)), url(
        'https://golencompliance.com/wp-content/uploads/2022/09/pexels-andrea-piacquadio-927451-1-scaled.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    padding-top: 30px;
    padding-bottom: 120px;
}

.wizard-body-subscription .wizard-header {
    padding: 24px 10%;
}
.wizard-body-subscription .wizard-header img {
    height: 80px;
}
</style>
