<template>
    <Dialog
        header='TÉRMINOS Y CONDICIONES'
        v-model:visible='displayTerms'
        :breakpoints="{'960px': '75vw', '640px': '90vw'}"
        :style="{width: '50vw'}"
        :modal='true'>
        <vue-pdf-app
            style="height: 100vh;"
            pdf='https://files-golex.s3.us-east-2.amazonaws.com/terms/TLL0C+Usuarios+Libres+GOLEN_v1.pdf' />
        <template #footer>
            <Button
                label='No Aceptar'
                icon='pi pi-times'
                @click='declineTerms'
                class='p-button-text' />
            <Button
                label='Aceptar Terminos'
                icon='pi pi-check'
                @click='AcceptTerms'
                autofocus />
        </template>
    </Dialog>

    <div class='wizard-body-subscription'>
        <div class='wizard-wrapper'>
            <div class='wizard-header'>
                <div class='wizard-logo'>
                    <router-link to='/subscription'>
                        <img src='layout/images/logo-h100.png' alt='babylon-layout' />
                    </router-link>
                </div>
            </div>

            <div class='wizard-content'>
                <div class="wizard-card">
                    <div class="wizard-card-content">
                        <div class="wizard-forms-wrapper grid grid-nogutter align-content-center align-items-center justify-content-center">
                            <div class='col-10 md:col-5 align-items-stretch'>
                                <div class='w-100 p-5'>
                                    <h1 class='mb-4 text-white'>¡Suscríbete! Lleva a tu organización un paso mas adelante con las
                                        nuevas normas
                                        legales.</h1>
                                    <div class='w-100 d-flex align-items-start'>
                                        <div class='icon d-flex align-items-center justify-content-center'>
                                            <span class='fa fa-paper-plane'></span>
                                        </div>
                                        <div class='text pl-4'>
                                            <p><span class='text-white-alpha-80'>Correo:</span> <a
                                                href='mailto:informes@golencompliance.com'>informes@golencompliance.com</a>
                                            </p>
                                        </div>
                                    </div>
                                    <div class='w-100 d-flex align-items-start'>
                                        <div class='icon d-flex align-items-center justify-content-center'>
                                            <span class='fa fa-globe'></span>
                                        </div>
                                        <div class='text pl-4'>
                                            <p><span class='text-white-alpha-80'>Sitio Web</span> <a
                                                href='https://golencompliance.com/'
                                                target='_blank'>golencompliance.com</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class='col-10 md:col-5'>
                                <div
                                    style='background-color: #0B2142;
                                                        border-radius: 30px'>
                                    <div
                                        style='padding: 20px 40px'>
                                        <div
                                            v-if='!isSubscribed'>
                                            <div class=''>
                                                <div class='md:col-12 py-2'>
                                                    <div>
                                                        <label
                                                            class='text-white'
                                                            for='nameUser'
                                                            :class="{'p-error':v$.nameUser.$errors.length>0}">Nombres<span
                                                            class='text-red-600'> *</span>
                                                        </label>
                                                        <InputText
                                                            id='nameUser'
                                                            type='text'
                                                            v-model.trim='v$.nameUser.$model'
                                                            class='w-full'
                                                            autocomplete='off'
                                                            placeholder='Ingrese nombres'
                                                            :class="{'p-invalid':v$.nameUser.$errors.length>0}" />
                                                        <small
                                                            v-if='(v$.nameUser.$errors.length>0)'
                                                            class='p-error'>
                                                            <p
                                                                v-for='error of v$.nameUser.$errors'
                                                                :key='error.$uid'>{{ error.$message }}</p>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div class='md:col-12 py-2'>
                                                    <div>
                                                        <label
                                                            class='text-white'
                                                            for='lastNameUser'
                                                            :class="{'p-error':v$.lastNameUser.$errors.length>0}">Apellidos<span
                                                            class='text-red-600'> *</span>
                                                        </label>
                                                        <InputText
                                                            id='lastNameUser'
                                                            type='text'
                                                            v-model.trim='v$.lastNameUser.$model'
                                                            class='w-full'
                                                            autocomplete='off'
                                                            placeholder='Ingrese apellidos'
                                                            :class="{'p-invalid':v$.lastNameUser.$errors.length>0}" />
                                                        <small
                                                            v-if='(v$.lastNameUser.$errors.length>0)'
                                                            class='p-error'>
                                                            <p
                                                                v-for='error of v$.lastNameUser.$errors'
                                                                :key='error.$uid'>{{ error.$message }}</p>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div class='md:col-12 py-2'>
                                                    <div>
                                                        <label
                                                            class='text-white'
                                                            for='emailUser'
                                                            :class="{'p-error':v$.emailUser.$errors.length>0}">Correo
                                                            Electronico<span
                                                                class='text-red-600'> *</span>
                                                        </label>
                                                        <InputText
                                                            id='emailUser'
                                                            type='text'
                                                            v-model.trim='v$.emailUser.$model'
                                                            class='w-full'
                                                            autocomplete='off'
                                                            placeholder='Ingrese correo electronico'
                                                            :class="{'p-invalid':v$.emailUser.$errors.length>0}" />
                                                        <small
                                                            v-if='(v$.emailUser.$errors.length>0)'
                                                            class='p-error'>
                                                            <p
                                                                v-for='error of v$.emailUser.$errors'
                                                                :key='error.$uid'>{{ error.$message }}</p>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div class='md:col-12 py-2'>
                                                    <div class='field'>
                                                        <label
                                                            class='text-white'
                                                            for='cellPhoneNumberUser'
                                                            :class="{'p-error':v$.cellPhoneNumberUser.$errors.length>0}">Numero
                                                            de
                                                            Celular<span class='text-red-600'> *</span>
                                                        </label>
                                                        <InputMask
                                                            id='cellPhoneNumberUser'
                                                            mask='999999999'
                                                            v-model.trim='v$.cellPhoneNumberUser.$model'
                                                            class='w-full'
                                                            autocomplete='off'
                                                            placeholder='Ingrese numero de celular'
                                                            :class="{'p-invalid':v$.cellPhoneNumberUser.$errors.length>0}" />
                                                        <small
                                                            v-if='(v$.cellPhoneNumberUser.$errors.length>0)'
                                                            class='p-error'>
                                                            <p
                                                                v-for='error of v$.cellPhoneNumberUser.$errors'
                                                                :key='error.$uid'>{{ error.$message }}</p>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div class='md:col-12 py-2'>
                                                    <div>
                                                        <label
                                                            class='text-white'
                                                            for='companyOrStudyCompany'
                                                            :class="{'p-error':v$.companyOrStudyCompany.$errors.length>0}">Empresa
                                                            o
                                                            Centro
                                                            de estudios<span class='text-red-600'> *</span>
                                                        </label>
                                                        <InputText
                                                            id='companyOrStudyCompany'
                                                            type='text'
                                                            v-model.trim='v$.companyOrStudyCompany.$model'
                                                            class='w-full'
                                                            autocomplete='off'
                                                            placeholder='Ingrese empresa o centro de estudios'
                                                            :class="{'p-invalid':v$.companyOrStudyCompany.$errors.length>0}" />
                                                        <small
                                                            v-if='(v$.companyOrStudyCompany.$errors.length>0)'
                                                            class='p-error'>
                                                            <p
                                                                v-for='error of v$.companyOrStudyCompany.$errors'
                                                                :key='error.$uid'>{{ error.$message }}</p>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div class='md:col-12 py-2'>
                                                    <div class='field'>
                                                        <label
                                                            class='text-white'
                                                            for='multiselectLegalMatters'
                                                            :class="{'p-error':v$.selectedLegalMatters.$errors.length>0}">Materias<span
                                                            class='text-red-600'> *</span>
                                                        </label>
                                                        <MultiSelect
                                                            id='multiselectLegalMatters'
                                                            class='w-full'
                                                            v-model='v$.selectedLegalMatters.$model'
                                                            :options='legalMatters'
                                                            :maxSelectedLabels='maxSelectedLabelsLegalMatters'
                                                            :selectAll='selectAllLegalMatters'
                                                            :selectionLimit='selectionLimitLegalMatters'
                                                            :selectedItemsLabel='itemsSelectedLabelLegalMatters'
                                                            @selectall-change='onSelectAllChangeLegalMatters($event)'
                                                            placeholder='Seleccione materias (maximo 5)'
                                                            @change='onChangeLegalMaters($event)'
                                                            dataKey='id'
                                                            optionLabel='name'
                                                            :filter='true'
                                                            :display='isChipLegalMatters'
                                                            :class="{'p-invalid':v$.selectedLegalMatters.$errors.length>0}"
                                                        >
                                                        </MultiSelect>
                                                        <small
                                                            v-if='(v$.selectedLegalMatters.$errors.length>0)'
                                                            class='p-error'>
                                                            <p
                                                                v-for='error of v$.selectedLegalMatters.$errors'
                                                                :key='error.$uid'>{{ error.$message }}</p>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div class='md:col-12 pb-2'>
                                                    <div>
                                                        <div class='field-checkbox'>
                                                            <Checkbox
                                                                inputId='binary'
                                                                v-model='v$.acceptTerms.$model'
                                                                :binary='true' />
                                                            <label
                                                                class='text-white font-bold pl-2 cursor-pointer'
                                                                @click='openTerms'
                                                                :class="{'p-error':v$.acceptTerms.$errors.length>0}"
                                                                for='binary'> {{ 'Acepta los Terminos y condiciones'
                                                                }}</label>
                                                        </div>
                                                        <small
                                                            v-if='(v$.acceptTerms.$errors.length>0)'
                                                            class='p-error'>
                                                            <p
                                                                v-for='error of v$.acceptTerms.$errors'
                                                                :key='error.$uid'>{{ error.$message }}</p>
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class='md:col-12 py-2'>
                                                <Button
                                                    style='background-color: #BF5305; border-color: #BF5305;'
                                                    @click='handleSubmit'
                                                    :disabled='v$.$invalid'
                                                >{{ btnName }}
                                                </Button>
                                            </div>
                                        </div>
                                        <div
                                            v-if='isSubscribed'>
                                            <p
                                                style='
                color: #FFFFFF;
                font-family: Helvetica,Sans-serif;
                font-weight:bold;
                font-size: 20px'>
                                                ¡¡¡Se ha suscrito de forma exitosa!!!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang='ts'>

import {
    computed, reactive, ref,
} from 'vue';
import useVuelidate from '@vuelidate/core';
import {
    email, helpers, minLength, required,
} from '@vuelidate/validators';
import LegalMatterAPI from '@/data/api/LegalMatterAPI';
import router from '@/router';
import { useToast } from 'primevue/usetoast';
import LegalMatter from '@/data/entity/LegalMatter';
import Subscription from '@/data/entity/Subscription';
import SubscriptionAPI from '@/data/api/SubscriptionAPI';

const toast = useToast();

let subscription: Subscription;

const btnName = ref('Suscribirme');

const legalMatters = ref<LegalMatter[]>();
const selectAllLegalMatters = ref(false);
const isChipLegalMatters = ref('chip');
const maxSelectedLabelsLegalMatters = ref(2);
const selectionLimitLegalMatters = ref(5);
const itemsSelectedLabelLegalMatters = ref('{0} materias seleccionadas');
const labelMultiSelectLegalMatters = ref('Materias');

const isSubscribed = ref(false);

const displayTerms = ref(false);

const formState = reactive({
    nameUser: '',
    lastNameUser: '',
    emailUser: '',
    cellPhoneNumberUser: '',
    companyOrStudyCompany: '',
    selectedLegalMatters: new Array<LegalMatter>(),
    acceptTerms: false,
});

const rules = computed(() => ({
        nameUser: {
            required: helpers.withMessage('Nombres son obligatorios', required),
        },
        lastNameUser: {
            required: helpers.withMessage('Apellidos son obligatorios', required),
        },
        emailUser: {
            required: helpers.withMessage('Correo es obligatorio', required),
            email: helpers.withMessage('Correo no es válido', email),
        },
        cellPhoneNumberUser: {
            required: helpers.withMessage('Número de celular es obligatorio', required),
            minLength: helpers.withMessage('Número de celular debe tener 9 dígitos', minLength(9)),
        },
        companyOrStudyCompany: {
            required: helpers.withMessage('Empresa o centro de estudio es obligatorio', required),
        },
        selectedLegalMatters: {
            required: helpers.withMessage('Materias son obligatorias', required),
            minLength: helpers.withMessage('Debe seleccionar al menos una materia', minLength(1)),
        },
        acceptTerms: {
            computed: helpers.withMessage('Debe aceptar los términos y condiciones', (value: boolean) => value === true),
        },
    }
));

const v$ = useVuelidate(rules, formState, { $autoDirty: true });

const handleSubmit = async () => {
    await v$.value.$validate();

    if (v$.value.$invalid) {
        toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Por favor, revise los campos del formulario',
            life: 3000,
        });
    } else {
        subscription = {
            subscriberName: formState.nameUser,
            subscriberLastName: formState.lastNameUser,
            subscriberEmail: formState.emailUser,
            subscriberCellPhoneNumber: formState.cellPhoneNumberUser,
            subscriberCompanyOrStudyCenter: formState.companyOrStudyCompany,
            subscriberAcceptTerms: formState.acceptTerms === true ? 1 : 0,
            legalMatters: formState.selectedLegalMatters as LegalMatter[],
        };
        await SubscriptionAPI.InsertSubscription(subscription)
            .then(async (response) => {
                toast.add({ severity: 'success', summary: 'Éxito', detail: response.message, life: 3000 });
                const subscriptionTmp = new Subscription();
                subscriptionTmp.id = response.subscriptionId;
                isSubscribed.value = true;
                await SubscriptionAPI.SendEmailForVerification(subscriptionTmp)
                    .then((responseEmail) => {
                        toast.add({ severity: 'success', summary: 'Éxito', detail: responseEmail.message, life: 3000 });
                    })
                    .catch((errorEmail) => {
                        toast.add({ severity: 'error', summary: 'Error', detail: errorEmail.message, life: 3000 });
                    });
            })
            .catch((error) => {
                toast.add({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
            });
    }
};

const mounted = async () => {
    const promises: Promise<any>[] = [];

    promises.push(LegalMatterAPI.GetLegalMatterActivesBySubscription());

    Promise.all(promises)
        .then((values) => {
            const [pLegalMatters] = values;

            legalMatters.value = pLegalMatters;
        })
        .catch(() => {
            toast.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar los datos', life: 3000 });
        })
        .finally(() => {
            toast.removeAllGroups();
        });
};
mounted();

const openTerms = () => {
    displayTerms.value = true;
};

const declineTerms = () => {
    formState.acceptTerms = false;
    displayTerms.value = false;
};

const AcceptTerms = () => {
    formState.acceptTerms = true;
    displayTerms.value = false;
};

const onSelectAllChangeLegalMatters = (event: any) => {
    formState.selectedLegalMatters = event.checked ? legalMatters.value! : [];
    selectAllLegalMatters.value = event.checked;
    itemsSelectedLabelLegalMatters.value = selectAllLegalMatters.value ? 'Todas las Materias' : '{0} materias seleccionadas';
    isChipLegalMatters.value = 'comma';
    labelMultiSelectLegalMatters.value = formState.selectedLegalMatters.length > 0
        ? 'Materias' : 'Materias: Seleccione materias o se considerara todas las materias';
};

const onChangeLegalMaters = (event: any) => {
    selectAllLegalMatters.value = event.value.length === legalMatters.value!.length;
    itemsSelectedLabelLegalMatters.value = selectAllLegalMatters.value ? 'Todas las Materias' : '{0} materias seleccionadas';
    isChipLegalMatters.value = event.value.length > maxSelectedLabelsLegalMatters.value ? 'comma' : 'chip';
    labelMultiSelectLegalMatters.value = formState.selectedLegalMatters.length > 0
        ? 'Materias' : 'Materias: Seleccione materias o se considerara todas las materias';
};

// eslint-disable-next-line no-unused-vars
const goToback = () => {
    router.go(-1);
};
</script>

<style lang='scss' scoped>

.wizard-body-subscription {
    background-image:
        linear-gradient(45deg,
            rgb(11, 33, 66,0.7),
            rgb(11, 33, 66,0.7)), url(
        'https://golencompliance.com/wp-content/uploads/2022/09/pexels-andrea-piacquadio-927451-1-scaled.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    padding-top: 30px;
    padding-bottom: 120px;
}

.wizard-body-subscription .wizard-header {
    padding: 24px 10%;
}
.wizard-body-subscription .wizard-header img {
    height: 40px;
}
</style>
