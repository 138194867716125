<template>
    <div class='layout-topbar clearfix'>
        <button class='layout-topbar-logo p-link' @click='goDashboard'>
            <img id='layout-topbar-logo' alt='babylon-layout' src='layout/images/logo-h100w.png' />
        </button>

        <button class='layout-menu-button p-link' @click='onMenuButtonClick'>
            <i class='pi pi-bars'></i>
        </button>

        <span
            class='p-text-user-login'>{{ user.accessTypeName + ': ' + user.name + ' ' + user.lastName }}</span>

        <button id='topbar-menu-button' class='p-link' @click='onTopbarMenuButtonClick'>
            <i class='pi pi-ellipsis-v'></i>
        </button>
        <ul :class='topbarItemsClass'>
            <li v-if="profileMode === 'popup' || horizontal"
                :class="['user-profile', {'active-topmenuitem': activeTopbarItem === 'profile'}]"
                @click="$emit('topbar-item-click',{originalEvent:$event,item:'profile'})">
                <button class='p-link'>
                    <img alt='babylon-layout' src='layout/images/avatar-gray.png' style='border-radius: 30px' />
                    <span class='topbar-item-name'>{{ user.name }}</span>
                </button>

                <transition name='layout-submenu-container'>
                    <ul class='fadeInDown' v-show="activeTopbarItem === 'profile'">
                        <!--                        <li role='menuitem'>-->
                        <!--                            <button class='p-link'>-->
                        <!--                                <i class='pi pi-user'></i>-->
                        <!--                                <span>Profile</span>-->
                        <!--                            </button>-->
                        <!--                        </li>-->
                        <!--                        <li role='menuitem'>-->
                        <!--                            <button class='p-link'>-->
                        <!--                                <i class='pi pi-cog'></i>-->
                        <!--                                <span>Settings</span>-->
                        <!--                            </button>-->
                        <!--                        </li>-->
                        <!--                        <li role='menuitem'>-->
                        <!--                            <button class='p-link'>-->
                        <!--                                <i class='pi pi-envelope'></i>-->
                        <!--                                <span>Message</span>-->
                        <!--                            </button>-->
                        <!--                        </li>-->
                        <!--                        <li role='menuitem'>-->
                        <!--                            <button class='p-link'>-->
                        <!--                                <i class='pi pi-bell'></i>-->
                        <!--                                <span>Notifications</span>-->
                        <!--                            </button>-->
                        <!--                        </li>-->
                        <!--                        <Divider />-->
                        <li role='menuitem'>
                            <button class='p-link'
                                    @click='logOutLocal'>
                                <i class='pi pi-sign-out'></i>
                                <span>Cerrar Sesión</span>
                            </button>
                        </li>
                    </ul>
                </transition>
            </li>

            <li v-if='nEvaluationRevaluateToViewedCount > 0'>
                <button class='p-link'
                        @click='showNotificationSlide(4)'>
                    <i class='topbar-icon pi pi-download'></i>
                    <span class='topbar-item-name'>Revaluacion</span>
                    <span class='topbar-badge'>{{ nEvaluationRevaluateToViewedCount }}</span>
                </button>

            </li>

            <li v-if='nDocumentRequiredToViewedCount > 0'>
                <button class='p-link'
                        @click='showNotificationSlide(3)'>
                    <i class='topbar-icon pi pi-clone'></i>
                    <span class='topbar-item-name'>Documento Requerido</span>
                    <span class='topbar-badge'>{{ nDocumentRequiredToViewedCount }}</span>
                </button>

            </li>

            <li v-if='nLicenseToViewedCount > 0'>
                <button class='p-link'
                        @click='showNotificationSlide(2)'>
                    <i class='topbar-icon pi pi-shield'></i>
                    <span class='topbar-item-name'>Licencia</span>
                    <span class='topbar-badge'>{{ nLicenseToViewedCount }}</span>
                </button>

            </li>

            <li v-if='nNewLegalRuleToViewedCount > 0'>
                <button class='p-link'
                        @click='showNotificationSlide(1)'>
                    <i class='topbar-icon pi pi-book'></i>
                    <span class='topbar-item-name'>Nueva Norma</span>
                    <span class='topbar-badge'>{{ nNewLegalRuleToViewedCount }}</span>
                </button>
            </li>

            <li v-if='nAvailableLegalRuleToViewedCount > 0'>
                <button class='p-link'
                        @click='showNotificationSlide(5)'>
                    <i class='topbar-icon pi pi-check-circle'></i>
                    <span class='topbar-item-name'>Nueva Norma disponible</span>
                    <span class='topbar-badge'>{{ nAvailableLegalRuleToViewedCount }}</span>
                </button>
            </li>
            <li v-if='visibleProgressSpinner'>
                <ProgressSpinner
                    style='width: 40px; height: 40px; padding-top: 20px; padding-left: 10px; '
                    class='cursor-pointer'
                    strokeWidth='10'
                    fill='var(--surface-ground)'
                    animationDuration='.5s'
                    @click='toggleProgressSpinner($event)'
                    aria-label='Custom ProgressSpinner' />
                <OverlayPanel ref='opProgressSpinner'>
                    <div class='text-xl'>{{ title }}</div>
                    <div class='text-lg'>
                        Numero de Norma: <strong>{{ legalRuleNumberForProgressSpinner }}</strong></div>
                </OverlayPanel>
            </li>
            <li v-if='visibleProgressSpinnerRequirement'>
                <ProgressSpinner
                    style='width: 40px; height: 40px; padding-top: 20px; padding-left: 10px; '
                    class='cursor-pointer'
                    strokeWidth='10'
                    fill='var(--surface-ground)'
                    animationDuration='.5s'
                    @click='toggleProgressSpinnerRequirement($event)'
                    aria-label='Custom ProgressSpinner' />
                <OverlayPanel ref='opProgressSpinnerRequirement'>
                    <div class='text-xl'>{{ titleRequirement }}</div>
                    <div class='text-lg'>
                        Numero de Norma: <strong>{{ legalRuleNumberForProgressSpinnerRequirement }}</strong></div>
                    <div
                        v-if='articleNumberForProgressSpinnerRequirement !== ""'
                        class='text-lg'>
                        Numero de Articulo: <strong>{{ articleNumberForProgressSpinnerRequirement }}</strong></div>
                </OverlayPanel>
            </li>
        </ul>
        <Sidebar
            v-model:visible='visibleRight'
            :baseZIndex='10000'
            position='right'>
            <h3>Notificaciones ({{ notificationsToViewed.length }})</h3>
            <DataTable
                :loading='loadingTable'
                :value='notificationsToViewed'
                v-model:selection='selectedNotification'
                selectionMode='single'
                dataKey='id'
                sortField='id'
                :sortOrder='-1'
                stripedRows
                :rowClass='rowClass'
                @rowSelect='onNotificationSelect'
                responsiveLayout='scroll'>
                <Column
                    field='notificationTypeName'
                    style='width: 100%'>
                    <template #body='row'>
                        <span
                            v-if='row.data.notificationTypeId === 1'
                            class=''>{{
                                row.data.notificationTypeName + ' - ' + row.data.documentName
                            }}</span>
                        <span
                            v-else
                            class=''>{{ row.data.notificationTypeName }}</span>
                    </template>
                </Column>
            </DataTable>
        </Sidebar>
        <Toast position='top-center' group='appTopbar' />
    </div>
    <Dialog v-model:visible='visibleDialog' modal :header='title' position='topright' :style="{ width: '30%' }">
        <p v-html='message'>
        </p>
        <template #footer>
            <!--            <Button label='No' icon='pi pi-times' @click='visibleDialog = false' text />-->
            <Button label='Eliminar Notificación' icon='pi pi-check' @click='onNotificationClosed' autofocus />
        </template>
    </Dialog>
    <Dialog v-model:visible='visibleDialogSummarize' :header='title' position='top'
            :style="{ width: '30%' }">
        <p v-html='message'>
        </p>
        <template #footer>
            <!--            <Button label='No' icon='pi pi-times' @click='visibleDialog = false' text />-->
            <Button label='Cerrar' icon='pi pi-check' @click='visibleDialogSummarize = false' autofocus />
        </template>
    </Dialog>
    <Dialog v-model:visible='visibleDialogSummarizeRequirement' :header='titleRequirement' position='top'
            :style="{ width: '30%' }">
        <p v-html='messageRequirement'>
        </p>
        <template #footer>
            <!--            <Button label='No' icon='pi pi-times' @click='visibleDialog = false' text />-->
            <Button label='Cerrar' icon='pi pi-check' @click='visibleDialogSummarizeRequirement = false' autofocus />
        </template>
    </Dialog>
</template>

<script setup lang='ts'>

import {
    computed,
    defineProps,
    defineEmits,
    inject,
    ref,
    watch,
} from 'vue';
import Store from '@/data/Store';
import router from '@/router';
import NotificationAPI from '@/data/api/NotificationUserAPI';
import { useToast } from 'primevue/usetoast';
import NotificationUser from '@/data/entity/NotificationUser';
import moment from 'moment';
import { useFunctionCallStatusStore } from '@/stores/functionCallStatusStore';
import FunctionCallStatusAPI from '@/data/api/FunctionCallStatusAPI';
import LicenseAPI from '@/data/api/LicenseAPI';

const functionCallStatusStore = useFunctionCallStatusStore();

const toast = useToast();


const {
    auth,
    logOut,
} = inject<Store>('store', {});

const user = auth?.user!;
const license = auth?.license!;

const loadingTable = ref(false);
const notificationsToViewed = ref<any[]>([]);
const notificationsToViewedCount = ref(0);

const nNewLegalRuleToViewed = ref<any[]>([]);
const nNewLegalRuleToViewedCount = ref(0);

const nAvailableLegalRuleToViewed = ref<any[]>([]);
const nAvailableLegalRuleToViewedCount = ref(0);

const nLicenseToViewed = ref<any[]>([]);
const nLicenseToViewedCount = ref(0);

const nDocumentRequiredToViewed = ref<any[]>([]);
const nDocumentRequiredToViewedCount = ref(0);

const nEvaluationRevaluateToViewed = ref<any[]>([]);
const nEvaluationRevaluateToViewedCount = ref(0);

const nTmpType = ref(0);

const selectedNotification = ref();
const visibleRight = ref(false);

const visibleDialog = ref(false);
const visibleDialogSummarize = ref(false);
const visibleDialogSummarizeRequirement = ref(false)

const notification = ref<NotificationUser>(new NotificationUser());

const title = ref('');
const message = ref('');

const licenseUseAiLocked = ref(false);

const visibleProgressSpinner = ref(false);
const opProgressSpinner = ref();
const toggleProgressSpinner = (event: any) => {
    opProgressSpinner.value.toggle(event);
};

const legalRuleNumberForProgressSpinner = ref('');

const visibleProgressSpinnerRequirement = ref(false);
const opProgressSpinnerRequirement = ref();
const toggleProgressSpinnerRequirement = (event: any) => {
    opProgressSpinnerRequirement.value.toggle(event);
};

const legalRuleNumberForProgressSpinnerRequirement = ref('');
const articleNumberForProgressSpinnerRequirement = ref('');
const titleRequirement = ref('');
const messageRequirement = ref('');

const props = defineProps({
    topbarMenuActive: {
        type: Boolean,
        default: false,
    },
    activeTopbarItem: {
        type: String,
        default: null,
    },
    profileMode: {
        type: String,
        default: 'popup',
    },
    horizontal: {
        type: Boolean,
        default: false,
    },
});


const emit = defineEmits(['menubutton-click', 'topbar-menubutton-click', 'topbar-item-click']);

const onMenuButtonClick = (event: any) => {
    emit('menubutton-click', event);
};

const onTopbarMenuButtonClick = (event: any) => {
    emit('topbar-menubutton-click', event);
};

const goDashboard = () => {
    window.location.href = '/dashboard';
};

const topbarItemsClass = computed(() => {
    return ['topbar-menu fadeInDown', {
        'topbar-menu-visible': props.topbarMenuActive,
    }];
});

const logOutLocal = () => {
    if (typeof logOut === 'function') {
        logOut!();

        if(license.id == 60){
            // Para la redirección sin dejar historial en el navegador
            router.replace('/login/realplaza').catch(err => {
                // Manejar errores, por ejemplo, rutas no encontradas o problemas de redirección
                console.error(err);
            });
        } else {
            // Uso de router.replace para evitar el retorno al historial anterior
            router.replace('/login').catch(err => {
                // Manejar errores
                console.error(err);
            });
        }
    }
};

const mounted = async () => {
    localStorage.removeItem('dt-notifications-state');
    loadingTable.value = true;
    const promises: Promise<any>[] = [];
    promises.push(LicenseAPI.GetLicenseUseAiStatus(license!.id));
    promises.push(NotificationAPI.GetNotificationViewedByUser(user!.id));

    await Promise.all(promises)
        .then((values) => {
            const [
                pLicenseUseAiStatus,
                pNotifications,
            ] = values;
            licenseUseAiLocked.value = pLicenseUseAiStatus.licenseUseAiLocked === 1;

            if (licenseUseAiLocked.value === true && pLicenseUseAiStatus.functionGroupToken !== null ) {
                getGenerateSummarizeStatus(pLicenseUseAiStatus.legalRuleId, pLicenseUseAiStatus.functionGroupToken);
            }

            if(licenseUseAiLocked.value === true && pLicenseUseAiStatus.functionToken !== null ) {
                getUpdateAllArticlesFromMasterArticleByLegalRuleId(pLicenseUseAiStatus.legalRuleId, pLicenseUseAiStatus.functionToken);
            }

            notificationsToViewed.value = pNotifications;
            notificationsToViewedCount.value = pNotifications.length;

            nNewLegalRuleToViewed.value = pNotifications.filter((x: any) => x.notificationTypeId === 7);
            nNewLegalRuleToViewedCount.value = nNewLegalRuleToViewed.value.length;

            nLicenseToViewed.value = pNotifications.filter((x: any) => x.notificationTypeId === 2
                || x.notificationTypeId === 3 || x.notificationTypeId === 4 || x.notificationTypeId === 5);
            nLicenseToViewedCount.value = nLicenseToViewed.value.length;

            nDocumentRequiredToViewed.value = pNotifications.filter((x: any) => x.notificationTypeId === 1);
            nDocumentRequiredToViewedCount.value = nDocumentRequiredToViewed.value.length;

            nEvaluationRevaluateToViewed.value = pNotifications.filter((x: any) => x.notificationTypeId === 8);
            nEvaluationRevaluateToViewedCount.value = nEvaluationRevaluateToViewed.value.length;

            nAvailableLegalRuleToViewed.value = pNotifications.filter((x: any) => x.notificationTypeId === 9);
            nAvailableLegalRuleToViewedCount.value = nAvailableLegalRuleToViewed.value.length;
        })
        .catch(() => {
            loadingTable.value = false;
            console.log('Error al obtener las notificaciones');
            toast.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Error al obtener las notificaciones',
                life: 3000,
                group: 'appTopbar',
            });
        })
        .finally(() => {
            loadingTable.value = false;
        });
};

mounted();

const showNotificationSlide = (nType: number) => {
    visibleRight.value = true;
    nTmpType.value = nType;
    if (nType === 1) {
        notificationsToViewed.value = nNewLegalRuleToViewed.value;
        notificationsToViewedCount.value = nNewLegalRuleToViewedCount.value;
    } else if (nType === 2) {
        notificationsToViewed.value = nLicenseToViewed.value;
        notificationsToViewedCount.value = nLicenseToViewedCount.value;
    } else if (nType === 3) {
        notificationsToViewed.value = nDocumentRequiredToViewed.value;
        notificationsToViewedCount.value = nDocumentRequiredToViewedCount.value;
    } else if (nType === 4) {
        notificationsToViewed.value = nEvaluationRevaluateToViewed.value;
        notificationsToViewedCount.value = nEvaluationRevaluateToViewedCount.value;
    } else if (nType === 5) {
        notificationsToViewed.value = nAvailableLegalRuleToViewed.value;
        notificationsToViewedCount.value = nAvailableLegalRuleToViewedCount.value;
    }
};

const onNotificationSelect = async (event: any) => {
    title.value = event.data.notificationTypeName;
    message.value = '';

    if (event.data.notificationTypeId === 1) {
        message.value = `El documento ${event.data.documentName} esta por vencer, por favor tomar las precauciones.`;
    } else if (event.data.notificationTypeId === 2) {
        message.value = `Su licencia de plataforma esta por vencer en 60 dias, por favor tomar las precauciones.`;
    } else if (event.data.notificationTypeId === 3) {
        message.value = `Su licencia de plataforma esta por vencer en 30 dias, por favor tomar las precauciones.`;
    } else if (event.data.notificationTypeId === 4) {
        message.value = `Su licencia de plataforma esta por vencer en 15 dias, por favor tomar las precauciones.`;
    } else if (event.data.notificationTypeId === 5) {
        message.value = `Su licencia de plataforma esta por vencer en 5 dias, por favor tomar las precauciones.`;
    } else if (event.data.notificationTypeId === 7) {
        message.value = '<div class=\'text-xl\'><strong>' + event.data.issuingEntityName + '</strong></div>\n' +
            '<div class=\'text-lg\'><strong>' + event.data.legalRuleTypeName + ' N° ' + event.data.legalRuleNumber + '</strong></div>' +
            '<div class=\'text-base\'><strong>Titulo: </strong>' + event.data.legalRuleTitle + '</div>\n' +
            '<div class=\'text-base\'><strong>Fecha de Publicación: </strong>' + moment(event.data.legalRulePublicationDate).utcOffset('GMT-00:00').format('DD/MM/YYYY') + '</div>';
    } else if (event.data.notificationTypeId === 8) {
        message.value = `Se ha agregado una nueva evaluación.`;
    } else if (event.data.notificationTypeId === 9) {
        message.value = '<div class=\'text-xl\'><strong>' + event.data.issuingEntityName + '</strong></div>\n' +
            '<div class=\'text-lg\'><strong>' + event.data.legalRuleTypeName + ' N° ' + event.data.legalRuleNumber + '</strong></div>' +
            '<div class=\'text-base\'><strong>Titulo: </strong>' + event.data.legalRuleTitle + '</div>\n' +
            '<div class=\'text-base\'><strong>Fecha de Publicación: </strong>' + moment(event.data.legalRulePublicationDate).utcOffset('GMT-00:00').format('DD/MM/YYYY') + '</div>';
    } else {
        message.value = `No se ha encontrado el tipo de notificación.`;
    }
    visibleDialog.value = true;

    notification.value.id = event.data.id;

    await NotificationAPI.UpdateNotificationUserToViewed(notification.value)
        .then(() => {
            console.log('Se actualizo correctamente');
        }).catch(() => {
            console.log('Error al actualizar');
        }).finally(() => {
            console.log('Finalizo');
        });

    await mounted();

    await showNotificationSlide(nTmpType.value);

};

const onNotificationClosed = async () => {
    await NotificationAPI.UpdateNotificationUserToClosed(notification.value)
        .then(() => {
            console.log('Se actualizo correctamente');
        }).catch(() => {
            console.log('Error al actualizar');
        }).finally(() => {
            console.log('Finalizo');
        });

    visibleDialog.value = false;

    await mounted();

    await showNotificationSlide(nTmpType.value);
};

const rowClass = (data: any) => {
    if (data.viewed == 1) {
        return 'row-viewed';
    } else {
        return null;
    }
};

watch(
    [
        () => functionCallStatusStore.functionToken,
        () => functionCallStatusStore.functionGroupToken,
    ],
    ([
         newFunctionToken,
         newFunctionGroupToken,
     ]) => {
        if (newFunctionGroupToken !== null && functionCallStatusStore.legalRuleId !== null) {
            getGenerateSummarizeStatus(functionCallStatusStore.legalRuleId, newFunctionGroupToken);
        }

        if (newFunctionToken !== null && functionCallStatusStore.legalRuleRequirementId !== null) {
            getUpdateAllArticlesFromMasterArticleByLegalRuleId(functionCallStatusStore.legalRuleRequirementId, newFunctionToken);
        }
    },
);

const getGenerateSummarizeStatus = async (legalRuleId: number, functionGroupToken: string) => {
    visibleProgressSpinner.value = true;
    message.value = '';
    let keepChecking = true;

    while (keepChecking) {

        await FunctionCallStatusAPI.GetFunctionCallStatusForGroup(functionGroupToken)
            .then((response: any) => {
                const responseStatus = response.responseStatus;
                let responseMessage = response.message;
                let functionTypeId = response.functionTypeId;
                let legalRuleNumber = response.legalRuleNumber;
                let articlesNumberError = '';
                legalRuleNumberForProgressSpinner.value = legalRuleNumber;

                if (functionTypeId === 8) {
                    title.value = 'Generacion de Resumenes';
                } else if (functionTypeId === 9) {
                    title.value = 'Generacion de Recomendaciones';
                } else if (functionTypeId === 15) {
                    title.value = 'Generacion de Evidencias';
                }
                message.value = '<div class=\'text-xl\'><strong>' + responseMessage + '</strong></div>\n' +
                    '<div class=\'text-base\'><strong>Numero de Norma: </strong>' + legalRuleNumber + '</div>\n';

                if (responseStatus === 1) {
                    visibleDialogSummarize.value = true;
                    functionCallStatusStore.setReloadArticleList(1);
                    visibleProgressSpinner.value = false;
                    keepChecking = false;
                } else if (responseStatus === 2) {
                    visibleDialogSummarize.value = false;
                    functionCallStatusStore.setReloadArticleList(1);
                    visibleProgressSpinner.value = false;
                    keepChecking = false;
                } else if (responseStatus === 3) {
                    articlesNumberError = response.articlesNumberError;
                    message.value = message.value + '<div class=\'text-base\'><strong>Numero de los articulos con error: </strong>' + articlesNumberError + '</div>\n';
                    visibleDialogSummarize.value = true;
                    functionCallStatusStore.setReloadArticleList(1);
                    visibleProgressSpinner.value = false;
                    keepChecking = false;
                } else if (responseStatus === 4) {
                    visibleDialogSummarize.value = false;
                    visibleProgressSpinner.value = true;
                    keepChecking = true;
                }
                // console.log(response);
            })
            .catch((error) => {
                message.value = '<div class=\'text-xl\'><strong>' + error + '</strong></div>\n';
                visibleDialogSummarize.value = true;
                console.log(error);
                visibleProgressSpinner.value = false;
                keepChecking = false;
            })
            .finally(() => {
                functionCallStatusStore.functionGroupToken = null;
                functionCallStatusStore.legalRuleId = null;
            });

        if (keepChecking) {
            await new Promise(resolve => setTimeout(resolve, 30000)); // Esperar 30 segundos
        }
    }
};

const getUpdateAllArticlesFromMasterArticleByLegalRuleId = async (legalRuleId: number, functionToken: string) => {
    visibleProgressSpinnerRequirement.value = true;
    messageRequirement.value = '';
    let keepChecking = true;

    let functionStateId = 0;

    while (keepChecking) {

        await FunctionCallStatusAPI.GetFunctionCallStatus(functionToken)
            .then((response: any) => {
                let legalRuleNumber = response.legalRuleNumber;
                let articleNumber = response.articleNumber
                legalRuleNumberForProgressSpinnerRequirement.value = legalRuleNumber;
                articleNumberForProgressSpinnerRequirement.value = articleNumber;
                titleRequirement.value = 'Actualizacion de requisitos de articulos';

                functionStateId = response.functionStateId;
                const functionResponseStateId = response.functionResponseStateId;
                if (functionStateId === 3) {
                    if (functionResponseStateId === 2) {
                      messageRequirement.value = '<div class=\'text-xl\'><strong>Se finalizo la actualizacion de los articulos</strong></div>\n' +
                          '<div class=\'text-base\'><strong>Numero de Norma: </strong>' + legalRuleNumber + '</div>\n' +
                          '<div v-if=\'articleNumber !== ""\' class=\'text-base\'><strong>Numero de Articulo: </strong>' + articleNumber + '</div>\n';
                    } else if (functionResponseStateId === 3) {
                      messageRequirement.value = '<div class=\'text-xl\'><strong>Error en actualizacion de los articulos</strong></div>\n' +
                          '<div class=\'text-base\'><strong>Numero de Norma: </strong>' + legalRuleNumber + '</div>\n' +
                          '<div v-if=\'articleNumber !== ""\' class=\'text-base\'><strong>Numero de Articulo: </strong>' + articleNumber + '</div>\n';
                    }
                    visibleDialogSummarizeRequirement.value = true;
                    visibleProgressSpinnerRequirement.value = false;
                    functionCallStatusStore.setReloadArticleList(1);
                    keepChecking = false;
                }
                // console.log(response);
            })
            .catch((error) => {
                messageRequirement.value = '<div class=\'text-xl\'><strong>' + error + '</strong></div>\n';
                visibleDialogSummarizeRequirement.value = true;
                console.log(error);
                visibleProgressSpinnerRequirement.value = false;
                keepChecking = false;
            })
            .finally(() => {
                functionCallStatusStore.functionToken = null;
                functionCallStatusStore.legalRuleRequirementId = null;
            });

        if (keepChecking) {
            await new Promise(resolve => setTimeout(resolve, 30000)); // Esperar 30 segundos
        }
    }
};


</script>

<style lang='scss' scoped>
.p-text-user-login {
    float: left;
    width: 350px;
    height: 44px;
    line-height: 44px;
    text-align: left;
    font-size: 1rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    border-radius: 3px;
    color: white;
}

::v-deep(.row-viewed) {
    opacity: 0.5;
}


</style>