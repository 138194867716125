import { defineStore } from 'pinia';

export const useFunctionCallStatusStore = defineStore({
    id: 'functionCallStatus',
    state: () => ({
        functionToken: null as string | null,
        functionGroupToken: null as string | null,
        legalRuleId: null as number | null,
        legalRuleRequirementId: null as number | null,
        articleRequirementId: null as number | null,
        timeSet: null as string | null,
        reloadArticleList: 0,
    }),
    actions: {
        setFunctionCallStatusRequirementLegalRuleId(functionToken: string, legalRuleRequirementId: number) {
            this.functionToken = functionToken;
            this.legalRuleRequirementId = legalRuleRequirementId;
            this.timeSet = new Date().toLocaleString();
        },
        setFunctionCallStatusRequirementArticleId(functionToken: string, articleRequirementId: number) {
            this.functionToken = functionToken;
            this.articleRequirementId = articleRequirementId;
            this.timeSet = new Date().toLocaleString();
        },
        setFunctionCallStatusGroup(functionGroupToken: string, legalRuleId: number) {
            this.functionGroupToken = functionGroupToken;
            this.legalRuleId = legalRuleId;
            this.timeSet = new Date().toLocaleString();
        },
        setReloadArticleList(reloadArticleList: number) {
            this.reloadArticleList = reloadArticleList;
            this.timeSet = new Date().toLocaleString();
        }
    }
});