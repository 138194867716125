export default class EUserType {
  public static SUPERUSER = 1;

  public static LAWYER= 2;

  public static ADMIN= 3;

  public static USER= 4;

  public static LAWYER_USER= 5;
}
