<template>
    <Toast group='change-password' />
    <div class='login-body'
         style='background-color: #0B2142'>
        <div class='login-panel'></div>
        <div class='login-content'>
            <img src='layout/images/logo-h100.png' alt='Golen' />
            <h1><span>Cambia tu contraseña</span> golen</h1>
            <p>Bienvenido, utilice el formulario para cambiar tu contraseña.</p>

            <div class='login-input-wrapper'>
                <InputText
                    type='password'
                    v-model="v$.password.$model"
                    placeholder="Ingrese tu nueva contraseña"
                    required
                />
                <i class='pi pi-lock'></i>
            </div>
            <div class='login-input-wrapper'>
                <InputText
                    type='password'
                    @keyup.enter="changePassword"
                    v-model="v$.repeatPassword.$model"
                    placeholder="Repite tu contraseña"
                />
                <i class='pi pi-lock'></i>
            </div>
            <div class='login-input-wrapper text-right'>
                <a
                    @click='returnLogin'
                    class='font-medium text-white hover:text-gray-400 cursor-pointer'>
                    &lt;- Regresar al login
                </a>
            </div>

            <div class='login-input-wrapper'>
                <Button
                    class='w-full'
                    label="Cambiar"
                    :disabled="btnAcceptDisable"
                    :loading="loadingRecovery"
                    @click="changePassword"
                />
            </div>

        </div>
    </div>
</template>

<script setup lang="ts">
import {
  computed, inject, reactive, ref,
} from 'vue';
import { helpers, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { useToast } from 'primevue/usetoast';
import { useRoute } from 'vue-router';
import Store from '@/data/Store';
import router from '@/router';
import UserDto from '@/data/entity/dto/UserDto';
import UserAPI from '@/data/api/UserAPI';

const {
  auth,
} = inject<Store>('store', {});

if (auth?.authenticate) {
    console.log('Primero cierre session');
  router.push('/');
}

const recoveryId = useRoute().query?.code as string;

if (!recoveryId) {
  router.push('/login');
}

const toast = useToast();

const loadingRecovery = ref(false);

const formState = reactive({
  password: '',
  repeatPassword: '',
});

const rules = computed(() => ({
  password: {
    required: helpers.withMessage(`El password es obligatorio`, required),
  },
  repeatPassword: {
    required: helpers.withMessage(`Repetir el password es obligatorio`, required),
  },
}));

const v$ = useVuelidate(rules, formState, { $autoDirty: true });

const btnAcceptDisable = computed(() => {
  if (v$.value.$invalid) {
    return true;
  }

  if (formState.password !== formState.repeatPassword) {
    return true;
  }
  return false;
});

const returnLogin = async () => {
  setTimeout(() => {
    router.push('/login');
  }, 300);
};

const changePassword = async () => {
  loadingRecovery.value = true;

  const user = new UserDto();
  user.token = recoveryId;
  user.password = formState.password;
  user.repeatPassword = formState.repeatPassword;

  UserAPI.ChangePassword(user)
    .then((data) => {
        console.log('data', data);
        toast.add({
          severity: 'success',
          summary: 'Cambio de contraseña',
          detail: 'La contraseña se ha cambiado correctamente',
          life: 3000,
          group: 'change-password',
        });
      setTimeout(() => {
        router.push('/login');
      }, 1000);
    })
    .catch((error) => {
        console.log('error', error);
      toast.add({
        severity: 'error',
        summary: 'Cambio de contraseña',
        life: 3000,
        group: 'change-password',
      });
    })
    .finally(() => {
      loadingRecovery.value = false;
    });
};

</script>
