import api from '@/helpers/AxiosConfig';
import BaseApi from '@/data/api/BaseApi';
import NotificationUser from '@/data/entity/NotificationUser';

const URL = 'https://fel0iakng8.execute-api.us-east-2.amazonaws.com/dev/api/notifications';

class NotificationUserAPI extends BaseApi {
  constructor() {
    super();
    this.url = URL;
  }

  GetNotificationViewedByUser = async (pUserId: number): Promise<any[]> => {
    const response = await api.get<any[]>(`${this.url}/notification-viewed-by-user`, { params: { userId: pUserId } });
    return (response && response.data) || null;
  };

  UpdateNotificationUserToViewed = async (notification?: NotificationUser): Promise<any> => {
    const response = await api.put<any>(`${this.url}/notification-user-to-viewed`, notification);
    return (response && response.data) || null;
  };

  UpdateNotificationUserToClosed = async (notification?: NotificationUser): Promise<any> => {
    const response = await api.put<any>(`${this.url}/notification-user-to-closed`, notification);
    return (response && response.data) || null;
  };

  InsertNotificationsBySubscriberNewLegalRule = async (legalRuleSubscription?: any): Promise<any> => {
    const response = await api.post<any>(`${this.url}/by-subscriber-new-legal-rule`, legalRuleSubscription);
    return (response && response.data) || null;
  };

  sendEmailForSubscriberNewLegalRule = async (pLicenseId: number, pUserId: number): Promise<any> => {
    const response = await api.get<any>(`${this.url}/send-email-for-subscriber-new-legal-rule`,{
      params: {
        licenseId: pLicenseId,
        userId: pUserId,
      },
    });
    return (response && response.data) || null;
  };

  InsertNotificationsByNewLegalRule = async (legalRule?: any): Promise<any> => {
    const response = await api.post<any>(`${this.url}/by-new-legal-rule`, legalRule);
    return (response && response.data) || null;
  };

  sendEmailForNewLegalRule = async (pLicenseId: number, pUserId: number): Promise<any> => {
    const response = await api.get<any>(`${this.url}/send-email-for-new-legal-rule`,{
      params: {
        licenseId: pLicenseId,
        userId: pUserId,
      },
    });
    return (response && response.data) || null;
  };

  InsertNotificationsByAvailableLegalRule = async (legalRule?: any): Promise<any> => {
    const response = await api.post<any>(`${this.url}/by-available-legal-rule`, legalRule);
    return (response && response.data) || null;
  };

  sendEmailForAvailableLegalRule = async (pLicenseId: number, pUserId: number): Promise<any> => {
    const response = await api.get<any>(`${this.url}/send-email-for-available-legal-rule`,{
      params: {
        licenseId: pLicenseId,
        userId: pUserId,
      },
    });
    return (response && response.data) || null;
  };

}

export default new NotificationUserAPI();
