import LegalMatter from '@/data/entity/LegalMatter';

export default class Subscription {
  id?: number;

  subscriberId?: number;

  subscriberName?: string;

  subscriberLastName?: string;

  subscriberDocumentNumber?: string;

  subscriberEmail?: string;

  subscriberEmailIsVerified?: number;

  subscriberCellPhoneNumber?: string;

  subscriberCompanyOrStudyCenter?: string;

  subscriberAcceptTerms?: number;

  subscriberToken?: string;

  subscriptionTypeId?: number;

  subscriptionTypeName?: string;

  countryId?: number;

  countryName?: string;

  regionId?: number;

  regionName?: string;

  legalMatters?: LegalMatter[];

  legalMatterId?: number;

  legalMatterName?: string;

  activityId?: number;

  activityName?: string;
}
