<template>
    <div class='exception-body  error'>
        <div class='exception-panel'></div>

        <div class='exception-content'>
            <img src='layout/images/logo-h100b.png' alt='babylon-layout' />
            <h1><span class='exception-name'>ERROR</span> OCCURRIDO</h1>
            <div>
                <p>
                    <span class='text-2xl text-red-900'>{{ messageTitle }} </span><br>
                    <span class='text-xl font-bold'>{{ messageBody }}</span>
                </p>
            </div>
            <a @click='goPageAfterError' style='cursor: pointer;'>{{ labelButton }}</a>
        </div>
    </div>
</template>

<script setup lang='ts'>
import { ref, onMounted, inject } from 'vue';
import router from '@/router';
import Store from '@/data/Store';

const {
    logOut,
} = inject<Store>('store', {});

const code = ref('');
const pathName = ref('');
const fullPath = ref('');
const messageTitle = ref('d');
const messageBody = ref('d');
const labelButton = ref('d');
const pathToRedirect = ref('');

const logOutLocal = () => {
    if (typeof logOut === 'function') {
        logOut!();

        // Uso de router.replace para evitar el retorno al historial anterior
        router.replace('/login').catch(err => {
            // Manejar errores
            console.error(err);
        });
    }
};

onMounted(() => {
    console.log('Se detecto error');
    code.value = router.currentRoute.value.query.code?.toString() || '';
    pathName.value = router.currentRoute.value.path;
    fullPath.value = router.currentRoute.value.fullPath;
    if (code.value === '0') {
        messageTitle.value = 'No esta autorizado';
        messageBody.value = 'Se cerro la sesión';
        labelButton.value = 'Volver a Iniciar Sesión';
        logOutLocal();
        pathToRedirect.value = '/login';
    } else {
        messageTitle.value = 'Error';
        messageBody.value = 'Se regresa al inicio';
        labelButton.value = 'Volver al Inicio';
        pathToRedirect.value = '/';
    }

});

const goPageAfterError = async () => {
    await router.push({ path: pathToRedirect.value });
};


</script>

<style scoped>

</style>