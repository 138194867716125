<template>
    <div
        class='home'
        v-if='store?.auth?.authenticate'>
    </div>
</template>

<script lang='ts'>
import { defineComponent, inject } from 'vue';
import { useRoute } from 'vue-router';
import Store from '@/data/Store';
import router from '@/router';
import ERolesType from '@/data/entity/enums/ERolesType';
import ERouteType from '@/router/ERouteType';

export default defineComponent({
    name: 'Home',
    components: {},
    inject: ['store'],
    setup() {
        router.push('/login');
        const {
            auth,
        } = inject<Store>('store', {});

        // const rolesUser = auth?.roles || [];
        const accessTypeId = auth?.accessTypeId || 0;
        // console.log('ruta', window.location.pathname);
        if (!auth?.authenticate) {
            // console.log('not authenticate');
            if (window.location.pathname === '/subscription') {
                router.push('/subscription');
            } else if (window.location.pathname === '/unsubscribe') {
                const params = new URLSearchParams(window.location.search);
                if (params.get('code')) {
                    router.push('/unsubscribe?code=' + params.get('code'));
                } else {
                    router.push('/unsubscribe');
                }
            } else if (window.location.pathname === '/recupera-tu-contrasena') {
                router.push('/recupera-tu-contrasena');
            } else if (window.location.pathname === '/cambia-tu-contrasena') {
                const params = new URLSearchParams(window.location.search);
                if (params.get('code')) {
                    router.push('/cambia-tu-contrasena?code=' + params.get('code'));
                } else {
                    router.push('/login');
                }
            } else if (window.location.pathname === '/cognito') {
                const params = new URLSearchParams(window.location.search);
                if (params.get('code')) {
                    router.push('/login?code=' + params.get('code') + '&corp=cognito');
                } else {
                    router.push('/login');
                }
            } else if (window.location.pathname === '/realplaza') {
                const params = new URLSearchParams(window.location.search);
                if (params.get('code')) {
                    router.push('/login?code=' + params.get('code') + '&corp=realplaza');
                } else {
                    router.push('/login');
                }
            } else if (window.location.pathname === '/login/realplaza') {
                router.push('/login/realplaza');
            }
            // else if (window.location.pathname === '/error') {
            //     console.log('estoy en la ruta de error no authenticate');
            //     router.push('/error');
            // }
            // else {
            //     router.push('/login');
            // }

        }
        // console.log('rutaaa2', window.location.pathname);

        if (window.location.pathname === '/error') {
            const params = new URLSearchParams(window.location.search);
            if (params.get('code')) {
                router.push('/error?code=' + params.get('code'));
            } else {
                router.push('/error');
            }
        } else {
            if (useRoute().path !== '/') {
                console.log('not home');
            }

            if (accessTypeId === ERolesType.LAWYER_USER) {
                router.push(ERouteType.LEGAL_RULE_LIST_BY_CRITERIA_PATH);
                return;
            }

            if (accessTypeId === ERolesType.USER) {
                router.push(ERouteType.LEGAL_MATRIX_LIST_PATH);
                return;
            }

            if (accessTypeId === ERolesType.ADMIN) {
                router.push(ERouteType.LEGAL_MATRIX_LIST_PATH);
                return;
            }

            if (accessTypeId === ERolesType.LAWYER) {
                router.push(ERouteType.LEGAL_RULE_LIST_PATH);
                return;
            }

            if (accessTypeId === ERolesType.SUPER_USER) {
                router.push(ERouteType.LICENSE_LIST_PATH);
            }
        }


    },
});
</script>
